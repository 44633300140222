import contactImage from "../Assessts/NewContact/contact.webp";

export const ContactForm = {
  title: "Let’s Get in Touch!",
  desc:
    "We're here to assist you. Have questions, feedback, or partnership inquiries? Submit your request below, drop us an email, or visit us at our office. Your satisfaction is our priority.",
};

export const ContactDetails = {
  image: contactImage,
  items: [
    {
      title: "info@wissda.com",
      logo: `<i class="bx bx-envelope"></i>`,
    },
    {
      title: "Wissda Incorporation, United States",
      desc: "260, Castle Drive, Englewood Cliffs, NJ - 07632",
      logo: `<i class="bx bx-map"></i>`,
    },
    {
      title: "Wissda Consulting Private Limited, India",
      desc: "Wissda Consulting Private Limited, India",
      logo: `<i class="bx bx-map"></i>`,
    },
  ],
};

export const ContactBreadCrubmsData = {
  title1: "Home",
  title2: "Contact Us",
  title3: "Contact Us",
};
