import React from "react";
import {
  ContactDetails,
  ContactForm,
  ContactBreadCrubmsData,
} from "../../Constants/newContact";

import styles from "./NewContact.module.css";
import { SeoTags } from "../../Components/SeoTags/SeoTags";
import { BreadCrumbs } from "../../Components/BreadCrumbs/Breadcrumbs";
import { ContactFormNew } from "../../Components/contact-form/contactForm";

const seoData = {
  title: "Contact Us",
  desc:
    "Have any questions about our services? Want to learn more about GRC and ESG? Contact Wissda for expert consulting services: info@wissda.com | Address: USA Wissda Incorporation, United States 260, Castle Drive, Englewood Cliffs, NJ - 07632. India Wissda Consulting Private Limited, India Mayo Center, Ground Floor, No. 12, Commissariat Road, Bangalore - 560025",
  canonical: "contact",
};

export const NewContact = () => {
  return (
    <>
      <SeoTags seoData={seoData} />
      <BreadCrumbs data={ContactBreadCrubmsData} />
      <div className={styles.contactMain}>
        <div className={styles.left}>
          <div className={styles.leftTitle}>{ContactForm.title}</div>
          <div className={styles.leftDesc}>{ContactForm.desc}</div>
          <div>
            <ContactFormNew />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightImage}>
            <img src={ContactDetails.image} alt="" />
          </div>
          <div className={styles.details}>
            {ContactDetails.items.map((contact, index) => (
              <div key={index} className={styles.eachItem}>
                <div
                  className={styles.eachIcon}
                  dangerouslySetInnerHTML={{ __html: contact.logo }}
                />
                <div>
                  <div className={styles.itemTitle}>{contact.title}</div>
                  <div className={styles.itemDesc}>{contact.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
